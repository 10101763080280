/* ViewRecord.css */

/* CSS Variables for Consistency */
:root {
  --primary-color: #3498db; /* Primary accent color (blue) */
  --secondary-color: #2c3e50; /* Dark background color (dark blue-gray) */
  --background-color: #ffffff; /* Light background color (white) */
  --text-color: #2c3e50; /* Main text color (dark blue-gray) */
  --muted-text-color: #7f8c8d; /* Muted text color (gray) */
  --spacing-unit: 1rem; /* Consistent spacing unit */
  --border-radius: 8px; /* Consistent border radius */
  --box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); /* Default box shadow */
  --hover-box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15); /* Hover box shadow */
}

/* Main Container */
.view-record-container {
  display: flex;
  flex-direction: row;
  height: 100vh;
  width: 100vw;
  padding: var(--spacing-unit);
  box-sizing: border-box;
  background-color: var(--background-color);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  overflow: hidden;
  font-family: 'Helvetica Neue', sans-serif;
  color: var(--text-color);
}

/* Sidebar */
.view-record-container .sidebar {
  width: 250px;
  padding: var(--spacing-unit);
  background-color: var(--secondary-color);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  margin-right: var(--spacing-unit);
  overflow-y: auto;
}

.view-record-container .sidebar-title {
  font-size: 1.5rem;
  color: #ffffff; /* White text for contrast */
  font-weight: 600;
  margin-bottom: var(--spacing-unit);
}

.view-record-container .serial-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.view-record-container .serial-item {
  padding: 0.75rem;
  margin-bottom: 0.75rem;
  background-color: #34495e; /* Slightly lighter than sidebar background */
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  color: #ffffff; /* White text for contrast */
}

.view-record-container .serial-item:hover {
  background-color: var(--primary-color); /* Highlight on hover */
  transform: translateY(-2px);
}

.view-record-container .serial-item.active {
  background-color: var(--primary-color); /* Active item color */
  color: white;
}

/* Main Content */
.view-record-container .main-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: var(--spacing-unit);
}

/* Header */
.view-record-container .title {
  font-size: 2rem;
  color: var(--primary-color); /* Primary color for the title */
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  margin-bottom: var(--spacing-unit);
}

/* Tabs */
.view-record-container .tabs {
  display: flex;
  justify-content: center;
  gap: var(--spacing-unit);
  margin-bottom: var(--spacing-unit);
}

.view-record-container .tab-button {
  background-color: var(--background-color);
  border: 2px solid var(--primary-color);
  border-radius: 30px;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
}

.view-record-container .tab-button:hover {
  background-color: #ecf0f1; /* Light gray on hover */
  transform: translateY(-2px);
  box-shadow: var(--box-shadow);
}

.view-record-container .tab-button.active {
  background-color: var(--primary-color); /* Primary color for active tab */
  color: white;
  border: 2px solid var(--primary-color);
  box-shadow: var(--hover-box-shadow);
  transform: translateY(-2px);
}

/* Record Card */
.view-record-container .record-card {
  margin-bottom: var(--spacing-unit);
  padding: var(--spacing-unit);
  background-color: var(--background-color);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.view-record-container .record-card:hover {
  transform: translateY(-5px);
  box-shadow: var(--hover-box-shadow);
}

.view-record-container .card-title {
  font-size: 1.5rem;
  color: var(--text-color); /* Dark text for contrast */
  font-weight: 600;
  border-bottom: 2px solid var(--primary-color); /* Primary color for accents */
  padding-bottom: 0.75rem;
  margin-bottom: var(--spacing-unit);
}

/* Info Group Grid */
.view-record-container .info-group {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: var(--spacing-unit);
}

/* Info Item Styling */
.view-record-container .record-info-item {
  padding: 1rem;
  background-color: #f7f9fb; /* Light gray background for info items */
  border-radius: 8px;
  box-shadow: var(--box-shadow);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.view-record-container .record-info-item:hover {
  transform: translateY(-5px);
  box-shadow: var(--hover-box-shadow);
}

.view-record-container .info-label {
  font-size: 1rem;
  color: var(--muted-text-color); /* Muted text color for labels */
  font-weight: 500;
}

.view-record-container .value-container {
  margin-top: 0.5rem;
}

.view-record-container .value-text {
  font-size: 1.2rem;
  color: var(--text-color); /* Dark text for values */
  font-weight: 600;
  display: flex;
  align-items: center;
}

.view-record-container .icon {
  margin-right: 0.5rem;
  color: var(--primary-color); /* Primary color for icons */
  font-size: 1.2rem;
}

.icon.success {
  color: #28a745; /* Green for success */
}

.icon.error {
  color: #dc3545; /* Red for error */
}

/* Responsive Design */
@media (max-width: 768px) {
  .view-record-container {
    flex-direction: column; /* Stack sidebar and main content vertically */
    height: auto;
    padding: 1rem;
  }

  .view-record-container .sidebar {
    width: 100%; /* Full width on smaller screens */
    margin-right: 0;
    margin-bottom: var(--spacing-unit);
  }

  .view-record-container .tabs {
    flex-direction: column; /* Stack tabs vertically on smaller screens */
    gap: 0.75rem;
  }

  .view-record-container .tab-button {
    width: 100%; /* Full-width tabs on smaller screens */
  }

  .view-record-container .info-group {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)); /* Adjust grid for smaller screens */
  }
}

/* Skeleton Loading Animation */
@keyframes shimmer {
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
}

.skeleton {
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
  border-radius: 4px;
  color: transparent !important; /* Hide text */
}

/* Sidebar Skeleton Styles */
.sidebar .skeleton-title {
  width: 80%;
  height: 1.5rem;
  margin-bottom: 1rem;
}

.sidebar .skeleton-item {
  width: 100%;
  height: 2.5rem;
  margin-bottom: 0.75rem;
}