.csv-upload-card {
    background: white;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 24px;
    width: 100%;
    max-width: 600px; 
    margin: 0 auto;
  }
  
  .csv-upload-card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
  }
  
  .csv-upload-card-title {
    font-size: 1.5rem;
    font-weight: 600;
    color: #1a1a1a;
    margin: 0;
  }
  
  .csv-close-button {
    background: none;
    border: none;
    color: #666;
    cursor: pointer;
    padding: 4px;
    border-radius: 4px;
    transition: background-color 0.2s;
  }
  
  .csv-close-button:hover {
    background-color: #f5f5f5;
  }
  
  .csv-drop-zone {
    border: 2px dashed #e0e0e0;
    border-radius: 8px;
    padding: 40px 20px;
    text-align: center;
    transition: all 0.2s ease;
    background: #fafafa;
    cursor: pointer;
  }
  
  .csv-drop-zone.dragging {
    border-color: #2196f3;
    background: #e3f2fd;
  }
  
  .csv-file-input {
    display: none;
  }
  
  .csv-upload-prompt {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    cursor: pointer;
  }
  
  .csv-upload-icon {
    color: #666;
  }
  
  .csv-upload-text {
    font-size: 1.1rem;
    color: #333;
  }
  
  .csv-upload-subtext {
    font-size: 0.9rem;
    color: #666;
  }
  
  .csv-file-selected {
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 12px 16px;
    background: white;
    border-radius: 6px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  }
  
  .csv-file-icon {
    color: #2196f3;
  }
  
  .csv-filename {
    flex: 1;
    font-size: 0.9rem;
    color: #333;
  }
  
  .csv-remove-file {
    background: none;
    border: none;
    color: #666;
    cursor: pointer;
    padding: 4px;
    border-radius: 4px;
    transition: background-color 0.2s;
  }
  
  .csv-remove-file:hover {
    background-color: #f5f5f5;
  }
  
  .csv-preview-section {
    margin-top: 16px;
}
  
  .csv-preview-title {
    font-size: 1rem;
    font-weight: 500;
    color: #333;
    margin-bottom: 8px; 
}
  
.csv-preview-table-container {
    overflow-x: auto;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    max-height: 400px; /* Optional: limit height */
    overflow-y: hidden;
}
  
  .csv-preview-table {
    width: 100%;
    border-collapse: collapse;
  }
  .csv-preview-table th,
  .csv-preview-table td {
    padding: 8px 12px;
    text-align: left;
    border-bottom: 1px solid #e0e0e0;
    font-size: 0.85rem; 

  }
  
  .csv-preview-table th {
    background: #f5f5f5;
    font-weight: 500;
    color: #333;
  }
  
  .csv-preview-table td {
    color: #666;
  }
  
  .csv-action-buttons {
    margin-top: 24px;
    display: flex;
    justify-content: flex-end;
  }
  
  .csv-next-button {
    background: #2196f3;
    color: white;
    border: none;
    padding: 10px 24px;
    border-radius: 6px;
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s;
  } 
  
  .csv-next-button:hover:not(.disabled) {
    background: #1976d2;
  }
  
  .csv-next-button.disabled {
    background: #e0e0e0;
    cursor: not-allowed;
  }
  
  