/* Root variables */
:root {
  --header-gradient: linear-gradient(90deg, #1e3a8a, #0284c7);
  --header-height: 72px;
  --content-max-width: 1400px;
}

/* Header base */
.header {
  background: var(--header-gradient);
  min-height: var(--header-height);
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 32px;
  position: relative;
  z-index: 50;
}

/* Main header wrapper */
.header-wrapper {
  width: 100%;
  max-width: var(--content-max-width);
  margin: 0 auto;
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  gap: 48px;
}

/* Title section */
.title-container {
  display: flex;
  align-items: center;
}

.header-title {
  color: #ffffff;
  font-size: 24px;
  font-weight: 600;
  margin: 0;
  white-space: nowrap;
  letter-spacing: -0.3px;
}

/* Navigation */
.header-nav {
  display: flex;
  align-items: center;
  gap: 24px;
}

.tab-button {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  background: transparent;
  border: none;
  border-radius: 6px;
  color: rgba(255, 255, 255, 0.9);
  font-size: 15px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.tab-button:hover {
  background: rgba(255, 255, 255, 0.1);
}

.tab-button.active {
  background: rgba(255, 255, 255, 0.15);
  color: #ffffff;
}

/* Header actions */
.header-actions {
  display: flex;
  align-items: center;
  gap: 16px;
}

/* Icon buttons */
.icon-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  padding: 0;
  background: transparent;
  border: none;
  border-radius: 6px;
  color: rgba(255, 255, 255, 0.9);
  cursor: pointer;
  transition: background-color 0.2s ease;
  position: relative;
}

.icon-button:hover {
  background: rgba(255, 255, 255, 0.1);
}

/* Profile button */
.profile-button {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 6px 12px;
  background: rgba(255, 255, 255, 0.1);
  border: none;
  border-radius: 6px;
  color: #ffffff;
  font-size: 15px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.profile-button:hover {
  background: rgba(255, 255, 255, 0.15);
}

/* Notification badge */
.notification-badge {
  position: absolute;
  top: 5px; 
  right: -2px; 
  width: 8px;
  height: 8px;
  background: #f97316; 
  border-radius: 50%;
}

/* Dropdowns */
.dropdown-container {
  position: relative;
}

.dropdown-menu {
  position: absolute;
  top: calc(100% + 8px);
  right: 0;
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  min-width: 200px;
  z-index: 100;
}

/* Notifications menu */
.notifications-menu {
  width: 320px;
  padding: 8px 0;
}

.notifications-menu h3 {
  margin: 0;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 600;
  color: #1f2937;
  border-bottom: 1px solid #e5e7eb;
}

.notification-item {
  padding: 12px 16px;
  cursor: pointer;
}

.notification-item:hover {
  background-color: #f3f4f6;
}

.notification-item p {
  margin: 0 0 4px 0;
  font-size: 14px;
  color: #1f2937;
}

.notification-item span {
  font-size: 12px;
  color: #6b7280;
}

.view-all {
  display: block;
  padding: 12px 16px;
  color: #2563eb;
  text-decoration: none;
  font-size: 14px;
  border-top: 1px solid #e5e7eb;
}

.view-all:hover {
  background-color: #f3f4f6;
}

/* Profile menu */
.profile-menu {
  min-width: 180px;
  padding: 8px;
}

.logout-button {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  padding: 8px 12px;
  background: transparent;
  border: none;
  border-radius: 6px;
  color: #ef4444;
  font-size: 14px;
  cursor: pointer;
}

.logout-button:hover {
  background-color: #fee2e2;
}

/* Mobile menu toggle */
.mobile-menu-toggle {
  display: none;
  background: transparent;
  border: none;
  padding: 8px;
  cursor: pointer;
}

/* Responsive design */
@media (max-width: 1024px) {
  .header {
    padding: 0 24px;
  }
  
  .header-wrapper {
    gap: 32px;
  }
}

@media (max-width: 768px) {
  .header {
    padding: 0 16px;
  }
  
  .header-nav {
    display: none;
  }
  
  .mobile-menu-toggle {
    display: block;
  }
  
  .header-title {
    font-size: 20px;
  }
  
  .profile-button span {
    display: none;
  }
}

/* Keep all previous CSS the same until the Dropdowns section */

/* Dropdowns */
.dropdown-container {
  position: relative;
  display: inline-block; /* This helps with positioning */
}

.dropdown-menu {
  position: absolute;
  top: calc(100% + 8px);
  right: 0;
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  min-width: 200px;
  z-index: 1000; /* Increased z-index */
  opacity: 1;
  visibility: visible; /* Ensure visibility */
  transform-origin: top right;
  animation: dropdownFadeIn 0.2s ease;
}

/* Animation for dropdown */
@keyframes dropdownFadeIn {
  from {
    opacity: 0;
    transform: scale(0.95) translateY(-10px);
  }
  to {
    opacity: 1;
    transform: scale(1) translateY(0);
  }
}

/* Notifications menu */
.notifications-menu {
  width: 320px;
  padding: 12px 8px; /* Added padding to match the design */
  background: white;
  margin-top: 4px;
}

.notifications-menu h3 {
  margin: 0;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 600;
  color: #1f2937;
}


.notification-item {
  padding: 12px 16px;
  border-bottom: 1px solid #e5e7eb;
  background: #EEF2FF; /* Light blue/indigo background */
  cursor: pointer;
  transition: background-color 0.2s ease;
  margin: 4px 8px;
  border-radius: 6px;
}

.notification-item:last-child {
  border-bottom: none;
}

.notification-item:hover {
  background-color: #E0E7FF;
}

.notification-item p {
  margin: 0 0 4px 0;
  font-size: 14px;
  color: #1f2937;
  font-weight: 500;
}

.notification-item span {
  font-size: 12px;
  color: #6b7280;
}

.view-all {
  display: block;
  padding: 12px 16px;
  color: #2563eb;
  text-decoration: none;
  font-size: 14px;
  border-top: 1px solid #e5e7eb;
  background: white; /* Ensure background is set */
}

.view-all:hover {
  background-color: #f3f4f6;
}

/* Profile menu */
.profile-menu {
  min-width: 180px;
  padding: 8px;
  background: white; /* Ensure background is set */
  margin-top: 4px; /* Add some spacing from the button */
}

.logout-button {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  padding: 8px 12px;
  background: transparent;
  border: none;
  border-radius: 6px;
  color: #ef4444;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.logout-button:hover {
  background-color: #fee2e2;
}

/* Active states for buttons */
.icon-button.active,
.profile-button.active {
  background: rgba(255, 255, 255, 0.2);
}

/* Keep all the responsive design CSS the same */