/* src/Login.css */
body, html {
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif;
    height: 100%;
    /* background: linear-gradient(135deg, #f4f4f6, #e0e0e8); */
    display: flex;
    justify-content: center;
    align-items: center;
}

.login-container {
    width: 100%;
    max-width: 400px;
    padding: 40px;
    background: white;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    text-align: center;
    animation: fadeIn 0.5s ease-in-out;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.login-container h2 {
    margin-bottom: 20px;
    font-size: 1.5rem;
    color: #333;
}

.form-group {
    margin-bottom: 15px;
    text-align: left;
}

.form-group label {
    display: block;
    font-weight: 500;
    color: #333;
    margin-bottom: 5px;
}

.form-group input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
    box-sizing: border-box;

}

.form-group input::placeholder {
    color: #999;
    font-size: 0.9rem;
}

.form-group input:focus {
    border-color: #0073e6;
    outline: none;
    box-shadow: 0 0 5px rgba(0, 115, 230, 0.5);
}

button[type="submit"] {
    width: 100%;
    padding: 10px;
    font-size: 1rem;
    color: white;
    background-color: #0073e6;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

button[type="submit"]:hover {
    background-color: #005bb5;
    transform: translateY(-2px);
}

button[type="submit"]:active {
    transform: translateY(0);
}

.footer-link {
    margin-top: 15px;
    font-size: 0.9rem;
    color: #0073e6;
    text-decoration: none;
}

.footer-link:hover {
    text-decoration: underline;
}

.loading-spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-left-color: #0073e6;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    animation: spin 1s linear infinite;
    margin: 0 auto;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}

.error-message {
    color: #dc3545;
    font-size: 0.9rem;
    margin-top: 10px;
    text-align: center;
    padding: 10px;
    background-color: #f8d7da;
    border: 1px solid #f5c6cb;
    border-radius: 4px;
}

.remember-me {
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.remember-me input {
    margin-right: 8px;
}

.remember-me label {
    font-size: 0.9rem;
    color: #333;
}

.forgot-password {
    display: block;
    margin-top: 10px;
    color: #0073e6;
    text-decoration: none;
    font-size: 0.9rem;
}

.forgot-password:hover {
    text-decoration: underline;
}

.social-login {
    margin-top: 20px;
}

.social-login button {
    width: 100%;
    padding: 10px;
    font-size: 1rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    transition: background-color 0.3s ease;
}

.social-login button.google {
    background-color: #dd4b39;
    color: white;
}

.social-login button.google:hover {
    background-color: #c23321;
}

.social-login button.facebook {
    background-color: #3b5998;
    color: white;
}

.social-login button.facebook:hover {
    background-color: #2d4373;
}

@media (max-width: 480px) {
    .login-container {
        padding: 20px;
        margin: 10px;
    }

    .login-container h2 {
        font-size: 1.3rem;
    }

    .form-group input {
        padding: 8px;
        font-size: 0.9rem;
    }

    button[type="submit"] {
        padding: 8px;
        font-size: 0.9rem;
    }
}
.password-container {
    position: relative;
    width: 100%;
  }

  .password-container input {
    width: 100%;
    padding: 10px 40px 10px 10px; 
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
    box-sizing: border-box; 
  }

  
.password-toggle-container {
    display: flex;
    align-items: center;
    width: 100%; /* Ensure the container takes full width */
  }
  
  .password-toggle-container input {
    flex: 1; /* Allow the input to expand and fill the space */
    margin-right: 10px; /* Add spacing between input and button */
  }
  
  .password-toggle-button {
    position: absolute;
    right: 10px; 
    top: 50%; 
    transform: translateY(-50%); 
    background: none;
    border: none;
    color: #0073e6;
    cursor: pointer;
    font-size: 1rem;
    padding: 5px;
    transition: color 0.3s ease;
  }
  
  .password-toggle-button:hover {
    color: #005bb5;
  }
  
  .password-toggle-button:focus {
    outline: none;
  }
  