/* Complaint Tracking Container */
.complaint-tracking-container {
  padding: 20px;
  background: #f5f5f5;
}

/* Complaint Tracking Card */
.complaint-tracking-card {
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
}

.complaint-tracking-card .ant-card-head-title {
  font-size: 18px;
  font-weight: bold;
}

/* Ant Table Styling */
.ant-table {
  background: #fff;
  border-radius: 8px;
}

.ant-table-thead > tr > th {
  background: #1890ff;
  color: #ffffff;
  font-weight: bold;
  text-align: center;
}

.ant-table-tbody > tr > td {
  text-align: center;
}

/* Button Styling */
.ant-btn {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-btn-primary {
  background-color: #1890ff;
  border-color: #1890ff;
}

.ant-btn-primary:hover {
  background-color: #40a9ff;
}

/* Drawer Header */
.ant-drawer-content {
  background: #ffffff;
}

.ant-drawer-header {
  background-color: #ffffff;
  border-bottom: 1px solid #e9ecef;
}

.ant-drawer-title {
  font-size: 1.25rem;
  font-weight: 500;
  color: #212529;
}

.ant-drawer-body {
  padding: 24px;
}

.complaint-details {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  padding: 16px;
}

.complaint-details p {
  margin: 0;
  padding: 12px;
  background-color: #f8f9fa;
  border-radius: 8px;
  border: 1px solid #e9ecef;
  transition: all 0.2s ease;
}

.complaint-details p:hover {
  background-color: #ffffff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.complaint-details strong {
  color: #212529;
  margin-right: 8px;
  font-weight: 500;
}

.generate-report-btn {
  grid-column: 1 / -1;
  margin-top: 24px;
  width: 100%;
  height: 40px;
  font-size: 14px;
}

/* Modal Styling */
.ant-modal-body {
  padding: 12px;
  max-height: 85vh;
}

/* Form Items */
.ant-form {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  width: 100%;
}

.ant-form-item {
  margin-bottom: 12px;
  width: 100%;
}

/* Form Fields */
.ant-picker {
  width: 100% !important;
}

/* Full-Width Textarea */
.ant-input[type="textarea"],
.ant-input-textarea textarea.ant-input {
  width: 100% !important;
  min-height: 100px !important;
  max-height: 300px;
  overflow-y: auto;
  resize: vertical;
  line-height: 1.5;
  padding: 8px 12px;
  font-size: 14px;
  border-radius: 6px;
  border: 1px solid #d9d9d9;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
  grid-column: 1 / -1; /* Span full width */
}

/* Textarea Focus Effect */
.ant-input[type="textarea"]:focus,
.ant-input-textarea textarea.ant-input:focus {
  border-color: #40a9ff;
  box-shadow: 0 0 5px rgba(24, 144, 255, 0.3);
  outline: none;
}

/* Full-Width Description Field */
.description-field {
  grid-column: 1 / -1; /* Span both columns */
  width: 100%;
}

/* Form Buttons Alignment */
.form-buttons {
  grid-column: 1 / -1;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

/* Responsive Behavior */
@media (max-width: 768px) {
  .ant-form {
    grid-template-columns: 1fr;
  }

  .form-buttons {
    justify-content: center;
  }
}

@media (max-height: 768px) {
  .ant-modal-body {
    max-height: 75vh;
  }

  .ant-input[type="textarea"],
  .ant-input-textarea textarea.ant-input {
    min-height: 80px !important;
  }
}

/* More Description Text */
.more-description-text {
  font-size: 14px;
  color: #555;
  background: #f8f8f8;
  padding: 10px;
  border-radius: 5px;
}

/* Search Section */
.search-section {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.search-input {
  width: 950px;
}

.warranty-select {
  width: 180px;
}

/* Modal Styles */
.complaint-modal {
  top: 5vh;
}

.complaint-modal .ant-modal-footer {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}

.complaint-modal .submit-button {
  margin-left: auto;
}

.complaint-form {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  padding: 20px 0;
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
  padding: 16px 24px;
  gap: 12px;
}

.cancel-button {
  font-size: 14px;
  height: 32px;
}

.submit-button {
  font-size: 14px;
  height: 32px;
  padding: 4px 15px;
  background-color: #1890ff;
}

/* Description Area */
.description-container {
  position: relative;
  grid-column: 1 / -1;
  margin-bottom: 12px;
}

.description-textarea {
  width: 100%;
  resize: none;
  padding-bottom: 36px;
}

.ai-assist-button-container {
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 8px;
  display: flex;
  align-items: center;
  background: #fff;
  border-top: 1px solid #f0f0f0;
}

.ai-assist-button {
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 13px;
  color: #1890ff;
  padding: 4px 8px;
}

/* AI Modal */
.ai-modal {
  width: 500px;
}

.ai-modal-footer {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  padding: 10px 16px;
}

.ai-modal-button {
  min-width: 80px;
  height: 32px;
  border-radius: 6px;
}

.ai-modal-content {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 8px 0;
}

.ai-query-section {
  margin-bottom: 12px;
  font-weight: 500;
}

.ai-query-textarea {
  border-radius: 6px;
}

.ai-fields-section {
  margin-bottom: 12px;
  font-weight: 500;
}

.ai-checkbox-group {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

/* Table Actions */
.table-actions {
  display: flex;
  justify-content: center;
  gap: 8px;
}

.action-button {
  padding: 4px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.action-button.view {
  color: #1890ff;
}

.action-button.delete {
  color: #ff4d4f;
}

.action-button:hover {
  background-color: #f5f5f5;
  border-radius: 4px;
}

/* Dropdown Menu Styles */
.action-menu {
  min-width: 120px;
}

.action-menu-item {
  padding: 8px 12px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.action-menu-item .anticon {
  font-size: 14px;
}

.action-menu-item.view-item {
  color: #1890ff;
  transition: all 0.2s ease;
}

.action-menu-item.view-item:hover {
  background-color: rgba(24, 144, 255, 0.1);
  color: #40a9ff;
  transform: translateX(4px);
}

.action-menu-item.view-item:active {
  transform: translateX(0);
}

.action-menu-item.delete-item {
  color: #ff4d4f;
}

.more-actions-button {
  width: 32px;
  height: 32px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.more-actions-button:hover {
  background-color: #f5f5f5;
  color: #1890ff;
}

/* Delete Confirmation Buttons */
.ant-modal-confirm-btns {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}

.ant-modal-confirm-btns .ant-btn {
  min-width: 64px;
}
