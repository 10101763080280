/* StoreTracking.css */

.product-tracking-container {
  padding: 24px;
  background-color: transparent; /* Remove any background here */
  min-height: 100vh;
  
}

.product-tracking-card {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  overflow: hidden;
  background-color: white;

}

.product-tracking-card .ant-card-head {
  color: black;
  padding: 16px 24px;
  border-bottom: none;
}

.product-tracking-card .ant-card-head-title {
  font-size: 18px;
  font-weight: 600;
}

.product-tracking-card .ant-card-body {
  padding: 24px;
}

/* Search and filter section */
.filter-section {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  margin-bottom: 24px;
  align-items: center;
}

/* Table styling */
.ant-table {
  border-radius: 8px;
  overflow: hidden;
}

.ant-table-thead > tr > th {
  background-color: white !important; /* Change header to white */
  color: black !important; /* Ensure text is visible */
  font-weight: bold;
  border-bottom: 1px solid #e8e8e8; /* Light border for separation */
}


.ant-table-tbody > tr:hover > td {
  background-color: #e6f7ff;
}

.ant-table-tbody > tr > td {
  padding: 12px;
}

/* Status badges */
.ant-badge-status-text {
  font-weight: 500;
}

/* Action buttons */
.ant-btn-icon-only {
  border-radius: 4px;
}

.action-button {
  margin-right: 8px;
}

/* Drawer styling */
.ant-drawer-header {
  background-color: #1890ff;
  border-radius: 0;
  border-bottom: none;
}

.ant-drawer-title {
  color: white;
  font-weight: 600;
}

.ant-drawer-close {
  color: white;
}

.ant-drawer-body {
  padding: 24px;
}

.ant-drawer-body p {
  margin-bottom: 16px;
  line-height: 1.6;
}

.ant-drawer-body strong {
  font-weight: 600;
  margin-right: 8px;
}

/* Add Product button */
.ant-btn-primary {
  background-color: #1890ff;
  border-color: #1890ff;
  box-shadow: 0 2px 0 rgba(24, 144, 255, 0.2);
}

.ant-btn-primary:hover {
  background-color: #40a9ff;
  border-color: #40a9ff;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .product-tracking-container {
    padding: 12px;
  }
  
  .product-tracking-card .ant-card-body {
    padding: 16px;
  }
  
  .filter-section {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .filter-section .ant-input,
  .filter-section .ant-select {
    width: 100%;
    margin-bottom: 8px;
  }
}