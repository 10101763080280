/* src/Home.css */
body, html {
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif;
    height: 100%;
    width: 100%; /* Ensure body and html take full width */
}

.home-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw; 
    background-image: url('/public/background.jpg'); /* Replace with your preferred image URL */
    background-size: cover;
    background-position: center;
    color: white;
    text-align: center;
    box-sizing: border-box; /* Include padding and border in element's total width and height */
}

.home-container h1 {
    font-size: 3rem;
    margin-bottom: 20px;
}

.home-container button {
    padding: 10px 20px;
    font-size: 1rem;
    color: white;
    background-color: rgba(0, 0, 0, 0.5);
    border: none;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.home-container button:hover {
    background-color: rgba(0, 0, 0, 0.7);
}

@media (max-width: 768px) {
    .home-container h1 {
        font-size: 2rem;
    }

    .home-container button {
        padding: 8px 16px;
        font-size: 0.9rem;
    }
}
