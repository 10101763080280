/* CSS Variables */
:root {
  --dropdown-bg: #ffffff; /* Background for dropdown */
  --dropdown-border: #ddd; /* Border color */
  --dropdown-shadow: 0 8px 12px rgba(0, 0, 0, 0.15); /* Enhanced shadow for depth */
  --dropdown-item-hover-bg: #f0f8ff; /* Subtle blue hover effect */
  --dropdown-item-hover-color: #0056b3; /* Hover text color */
  --dropdown-button-bg: #007bff; /* Button background */
  --dropdown-button-hover-bg: #0056b3; /* Button hover background */
  --dropdown-button-color: #ffffff; /* Button text color */
  --dropdown-icon-size: 16px; /* Icon size */
  --dropdown-min-width: 180px; /* Slightly increased width for clarity */
  --dropdown-padding: 10px; /* Consistent padding for dropdown items */
  --dropdown-border-radius: 6px; /* Smooth corners */
}

/* Action Dropdown Container */
.action-dropdown {
  position: relative;
  display: inline-flex;
  align-items: center;
}

/* Toggle Button Styles */
.dropdown-toggle {
  background: transparent;
  border: none;
  color: #007bff;
  padding: 8px 12px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  border-radius: var(--dropdown-border-radius); /* Rounded corners */
  transition: background 0.3s ease, color 0.3s ease;
}

.dropdown-toggle:hover {
  background: rgba(0, 123, 255, 0.1);
  color: var(--dropdown-item-hover-color);
}

.dropdown-toggle i {
  font-size: var(--dropdown-icon-size);
}

/* Dropdown Menu */
.dropdown-menu {
  position: absolute;
  top: calc(100% + 8px); /* Increased spacing for clarity */
  right: 0;
  z-index: 1050;
  background: var(--dropdown-bg);
  border: 1px solid var(--dropdown-border);
  border-radius: var(--dropdown-border-radius);
  box-shadow: var(--dropdown-shadow);
  list-style: none;
  margin: 0;
  padding: 0;
  min-width: var(--dropdown-min-width);
  white-space: nowrap;
  opacity: 0;
  visibility: hidden;
  transform: translateY(10px);
  transition: all 0.25s ease-in-out;
}

/* Open Dropdown Menu */
.action-dropdown.open .dropdown-menu {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

/* Dropdown Menu Items */
.dropdown-menu li {
  display: block;
}

.dropdown-menu button {
  width: 100%;
  background: none;
  border: none;
  color: #333;
  padding: var(--dropdown-padding);
  text-align: left;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  transition: background 0.2s ease, color 0.2s ease;
}

.dropdown-menu button:hover {
  background: var(--dropdown-item-hover-bg);
  color: var(--dropdown-item-hover-color);
}

.dropdown-menu i {
  margin-right: 10px; /* Better spacing for icons */
  font-size: var(--dropdown-icon-size);
}

/* Handle Left-Aligned Dropdowns */
.dropdown-menu.open-left {
  right: auto;
  left: 0;
}

/* Table Cell Styling */
.actions-cell {
  text-align: center;
  padding: 4px;
  white-space: nowrap;
}

/* Responsive Styling */
@media (max-width: 768px) {
  .dropdown-menu {
    min-width: 140px; /* Adjust width for smaller screens */
  }

  .dropdown-toggle {
    font-size: 12px; /* Smaller font for compact screens */
  }

  .dropdown-menu button {
    font-size: 12px;
    padding: 8px; /* Reduced padding */
  }
}
