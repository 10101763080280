/* Modal.css */

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    animation: fadeIn 0.3s ease-out;
  }
  
  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
  
  .modal-content {
    background: white;
    padding: 36px;
    border-radius: 12px;
    width: 90%;
    max-width: 600px;
    max-height: 85vh;
    overflow-y: auto;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
    animation: slideIn 0.3s ease-out;
    position: relative;
  }
  
  @keyframes slideIn {
    from { transform: translateY(-50px); opacity: 0; }
    to { transform: translateY(0); opacity: 1; }
  }
  
  .close-button {
    background: transparent;
    border: none;
    font-size: 28px;
    cursor: pointer;
    position: absolute;
    right: 20px;
    top: 20px;
    color: #3498DB;
    transition: all 0.3s ease;
  }
  
  .close-button:hover {
    color: #2980B9;
    transform: scale(1.1);
  }
  
  /* Tab styling */
  .tabs {
    display: flex;
    margin-bottom: 24px;
    border-bottom: 2px solid #E0E0E0;
  }
  
  .tab-button {
    padding: 12px 24px;
    border: none;
    background: transparent;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    color: #7F8C8D;
    transition: all 0.3s ease;
    position: relative;
  }
  
  .tab-button::after {
    content: '';
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #3498DB;
    transform: scaleX(0);
    transition: transform 0.3s ease;
  }
  
  .tab-button:hover {
    color: #3498DB;
  }
  
  .tab-button.active {
    color: #3498DB;
  }
  
  .tab-button.active::after {
    transform: scaleX(1);
  }
  
  .tab-content {
    margin-top: 24px;
  }
  
  .section-header {
    cursor: pointer;
    color: #34495E;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 16px;
    transition: color 0.3s ease;
    display: flex;
    align-items: center;
  }
  
  .section-header:hover {
    color: #3498DB;
  }
  
  .section-header::before {
    content: '▶';
    font-size: 14px;
    margin-right: 8px;
    transition: transform 0.3s ease;
  }
  
  .section-header.open::before {
    transform: rotate(90deg);
  }
  
  /* Responsive styling for modal */
  @media (max-width: 768px) {
    .modal-content {
      width: 95%;
      padding: 24px;
    }
    
    .tab-button {
      padding: 10px 16px;
      font-size: 14px;
    }
  }