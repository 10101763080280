/* Dashboard.css */

/* Global box-sizing */
* {
  box-sizing: border-box;
}

/* Dashboard container */
.dashboard {
  font-family: 'Roboto', Arial, sans-serif;
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-template-rows: auto 1fr;
  gap: 24px;
  height: 100vh;
  padding: 24px;
  background-color: #F0F2F5;
}

/* Header styling */
.header {
  grid-column: 1 / -1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 28px;
  font-weight: bold;
  background: linear-gradient(135deg, #2C3E50, #34495E);
  color: white;
  padding: 24px;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Header buttons */
.header-buttons {
  display: flex;
  gap: 12px;
}

/* Unified button styling */
.button {
  background-color: #3498DB;
  border: none;
  color: white;
  padding: 12px 18px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  border-radius: 6px;
  transition: all 0.3s ease;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.button:hover {
  background-color: #2980B9;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.button.secondary {
  background-color: #7F8C8D;
}

.button.secondary:hover {
  background-color: #6C7A89;
}

/* Sidebar styling */
.sidebar {
  grid-row: 2 / 3;
  background-color: white;
  padding: 24px;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Main content styling */
.main-content {
  grid-row: 2 / 3;
  background-color: white;
  padding: 24px;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
}

/* Input field styling */
.input {
  width: 100%;
  padding: 12px;
  margin: 12px 0;
  border-radius: 6px;
  border: 1px solid #BDC3C7;
  font-size: 16px;
  transition: all 0.3s ease;
}

.input:focus {
  border-color: #3498DB;
  box-shadow: 0 0 0 3px rgba(52, 152, 219, 0.25);
  outline: none;
}

/* Records header styling */
.records-header {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #2C3E50;
  border-bottom: 2px solid #3498DB;
  padding-bottom: 10px;
}

/* No records message styling */
.no-records {
  text-align: center;
  color: #7F8C8D;
  margin-top: 24px;
  font-style: italic;
}

/* Form section styling */
.form-section {
  margin-bottom: 24px;
  padding: 16px;
  border-radius: 8px;
  transition: all 0.3s ease;
}

.form-section:hover {
  background-color: #F7F9FA;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

/* Form label styling */
.form-label {
  font-weight: 600;
  margin-bottom: 12px;
  color: #34495E;
}

/* Table styling */
.table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  margin-top: 24px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.table th, .table td {
  border: 1px solid #E0E0E0;
  padding: 12px;
  text-align: left;
}

.table th {
  background-color: #F7F9FA;
  font-weight: 600;
  color: #34495E;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.table tr:nth-child(even) {
  background-color: #F9FAFB;
}

.table tr:hover {
  background-color: #EBF5FB;
}

/* Responsive styling */
@media (max-width: 768px) {
  .dashboard {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto 1fr;
    padding: 16px;
  }

  .header {
    flex-direction: column;
    align-items: stretch;
    text-align: center;
  }

  .header-buttons {
    margin-top: 16px;
  }

  .sidebar {
    grid-row: 2 / 3;
  }

  .main-content {
    grid-row: 3 / 4;
  }
}

.records-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  margin-top: 24px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.records-table th, .records-table td {
  border: 1px solid #E0E0E0;
  padding: 4px;
  text-align: left;
}

.records-table th {
  background-color: #F7F9FA;
  font-weight: 600;
  color: #34495E;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.records-table tr:nth-child(even) {
  background-color: #F9FAFB;
}

.records-table tr:hover {
  background-color: #EBF5FB;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-buttons {
  display: flex;
  gap: 10px;
}

.button {
  padding: 8px 12px;
  font-size: 14px;
}

.button.add-record {
  background-color: #28a745;
  color: white;
  font-weight: bold;
}

.dropdown-menu {
  position: absolute;
  right: 0;
  top: 40px; /* Adjust based on the height of your header */
  background: white; /* or any background color */
  border: 1px solid #ddd; /* Optional border */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15); /* Optional shadow */
  z-index: 1000; /* Ensure it's above other content */
}

.header-buttons {
  position: relative; /* Ensure dropdown is positioned relative to this */
}

.button {
  margin: 5px; /* Optional spacing */
}

.card {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-bottom: 20px; /* Space between cards */
}

/* Container styling */
.search-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 20px;
  font-family: 'Arial', sans-serif;
  background-color: #f9f9f9;
}

/* Card styling */
.card {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 100%;
  max-width: 600px;
}

/* Form label styling */
.form-label {
  font-weight: bold;
  margin-bottom: 10px;
  display: block;
}

/* Input group for buttons and input field */
.input-group {
  display: flex;
  gap: 10px;
  align-items: center;
}

/* Input field styling */
.input-field {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

.primary-button {
  background-color: #007bff;
  color: #fff;
}

.primary-button:hover {
  background-color: #0056b3;
}

.search-button {
  background-color: #28a745;
  color: #fff;
}

.search-button:hover {
  background-color: #218838;
}

.clear-button {
  background-color: #ff4d4d;
  color: #fff;
}

.clear-button:hover {
  background-color: #d93636;
}

.upload-button {
  margin-left: 15px;
  padding: 8px 16px;
  font-size: 14px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  background-color: #007bff;
  color: white;
}
.upload-button:hover {
  background-color: #0056b3;
}
.upload-card {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  width: 100%;
  z-index: 1000;
}

.upload-card h4 {
  text-align: center;
  font-size: 24px;
  margin-bottom: 20px;
}

.upload-card .file-input {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.upload-card .preview-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.custom-file-btn {
  display: inline-block;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  text-align: center;
  transition: background-color 0.3s ease;
}

.custom-file-btn:hover {
  background-color: #0056b3;
}

/* Tab Navigation */
.tab-navigation {
  display: flex;
  margin-bottom: 20px;
  border-bottom: 1px solid #ccc;
}

.tab-button {
  padding: 10px 20px;
  cursor: pointer;
  background-color: #f1f1f1;
  border: none;
  outline: none;
  transition: background-color 0.3s ease;
}

.tab-button.active {
  background-color: #007bff;
  color: white;
}

.tab-button:hover {
  background-color: #ddd;
}

/* Tab Content */
.tab-content {
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.other-code-content {
  text-align: center;
  padding: 20px;
}